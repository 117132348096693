import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import ImgFull from "../components/ImgFull";
import Layout from "../components/Layout";

export const IndexPageTemplate = ({
  image,
  heading,
  topbanner,
  thumbnailbanner,
  middlebanner,
  imagebanner,
  mapbanner
}) => (
  <div>
    <div className="header">
      <ImgFull image={image} />
      <div className="tagline">
        <br />
        {heading}
      </div>
      <div className="tagline-logo">
        Gamot Cogon
        <br /> Waldorf School
      </div>
      <div className="transparency"></div>
    </div>

    <div className="banner">
      <Link to={topbanner.left.link}>
        <div className="tile-2 text">
          <div className="title">{topbanner.left.title}</div>
          <div className="subtext">{topbanner.left.body}</div>
        </div>
      </Link>
      <Link to={topbanner.left.link}>
        <div className="tile green text white">
          <div className="title">{topbanner.right.title}</div>
          <div className="subtext">{topbanner.right.body}</div>
        </div>
      </Link>
    </div>

    <div className="banner pointer">
      <div className="tile-v">
        <div className="frame">
          <ImgFull
            image={thumbnailbanner.left.image.image}
            alt={thumbnailbanner.left.image.alt}
          />
        </div>

        <Link to={thumbnailbanner.left.link}>
          <div className="tile dot-green text">
            <div className="title">{thumbnailbanner.left.title}</div>
            <div className="subtext">{thumbnailbanner.left.body}</div>
            <div className="tile-footer">{/* Age 8 - 12 */}</div>
          </div>
        </Link>
      </div>

      <div className="tile-v">
        <div className="frame">
          <ImgFull image={thumbnailbanner.middle.image.image} />
        </div>

        <Link to={thumbnailbanner.middle.link}>
          <div className="tile dot-bamboo text">
            <div className="title">{thumbnailbanner.middle.title}</div>
            <div className="subtext">{thumbnailbanner.middle.body}</div>

            <div className="tile-footer">{/* Age 12 - 16 */}</div>
          </div>
        </Link>
      </div>

      <div className="tile-v">
        <div className="frame">
          <ImgFull image={thumbnailbanner.right.image.image} />
        </div>
        <Link to={thumbnailbanner.right.link}>
          <div className="tile dot-red text">
            <div className="title">{thumbnailbanner.right.title}</div>
            <div className="subtext">{thumbnailbanner.right.body}</div>

            <div className="tile-footer">{/* Age 16 - 18 */}</div>
          </div>
        </Link>
      </div>
    </div>

    <div className="banner">
      <Link to={middlebanner.left.link}>
        <div className="tile green text white">
          <div className="title">{middlebanner.left.title}</div>
          <div className="subtext">{middlebanner.left.body}</div>
        </div>
      </Link>
      <Link to={middlebanner.right.link}>
        <div className="tile-2 text bamboo white">
          <div className="title">{middlebanner.right.title}</div>
          <div className="subtext">{middlebanner.right.body}</div>
        </div>
      </Link>
    </div>

    <div className="banner pointer hide-m">
      <div className="tile">
        <div className="frame">
          <ImgFull
            image={imagebanner.image1.image}
            alt={imagebanner.image1.alt}
          />
          {/* <img src="img/DSC03354.JPG" alt=""/> */}
        </div>
      </div>
      <div className="tile">
        <div className="frame">
          <ImgFull
            image={imagebanner.image2.image}
            alt={imagebanner.image2.alt}
          />
          {/* <img src="img/DSC03572.JPG" alt=""/> */}
        </div>
      </div>
      <div className="tile">
        <div className="frame">
          <ImgFull
            image={imagebanner.image3.image}
            alt={imagebanner.image3.alt}
          />
          {/* <img src="img/DSC03212.JPG" alt=""/> */}
        </div>
      </div>
    </div>

    <div className="banner">
      <Link to={mapbanner.left.link}>
        <div className="tile text">
          <div className="title">{mapbanner.left.title}</div>
          <div className="subtext">{mapbanner.left.body}</div>
        </div>
      </Link>
      <div className="tile-2 map-tile">
        <div className="frame-2">
          {/* <img src="img/landscape-map.png" alt=""/> */}
          <iframe
            style={{ width: "100%", height: "100%" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4147.116326533194!2d122.63440815296003!3d10.809667761303949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33aee18e5c347f29%3A0xe77bed0d595fa549!2sGamot%20Cogon%20School!5e1!3m2!1sen!2suk!4v1580325162628!5m2!1sen!2suk"
            frameBorder="0"
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Layout>
        <IndexPageTemplate
          // images={images}
          // image={frontmatter.image}
          // title={frontmatter.title}
          // heading={frontmatter.heading}
          // subheading={frontmatter.subheading}
          // description={frontmatter.description}

          intro={frontmatter.intro}
          image={frontmatter.image}
          title={frontmatter.title}
          heading={frontmatter.heading}
          subheading={frontmatter.subheading}
          topbanner={frontmatter.topbanner}
          thumbnailbanner={frontmatter.thumbnailbanner}
          middlebanner={frontmatter.middlebanner}
          imagebanner={frontmatter.imagebanner}
          mapbanner={frontmatter.mapbanner}
        />
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        topbanner {
          left {
            link
            title
            body
          }
          right {
            link
            title
            body
          }
        }
        thumbnailbanner {
          left {
            link
            title
            body
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          middle {
            link
            title
            body
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          right {
            link
            title
            body
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        middlebanner {
          left {
            link
            title
            body
          }
          right {
            link
            title
            body
          }
        }
        imagebanner {
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        mapbanner {
          left {
            link
            title
            body
          }
        }
      }
    }
  }
`;
