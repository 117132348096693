import React from 'react'
import Img from 'gatsby-image'

export default ({image}) => {
  if(!!image.childImageSharp) {
    return <Img style={{height: '100%'}} fluid={image.childImageSharp.fluid} />
  }

  // We have to do this so that the Netlify CMS preview works
  return <img style={{height: '100%'}} src={image}/>
}